import { nativeApplicationVersion } from "expo-application";
import { Platform } from "react-native";

const CUR_VERSION = "1.0.34";
const clientVersion = (() => {
	const native = Platform.OS === "ios" || Platform.OS === "android";
	if (!native) return CUR_VERSION;
	switch (nativeApplicationVersion) {
		default:
			return CUR_VERSION;
	}
})();
export default clientVersion;
